import Context from 'contexts/Context'
import Footer from 'components/Footer';
import ProgressBar from 'components/elements/ProgressBar';
import Header from 'components/Header';
import ClippedText from 'components/sections/ClippedText';
import Hero from 'components/sections/Hero';
/* import HeroBlotterLiquid from 'components/sections/HeroBlotterLiquid'; */
import Summary from 'components/sections/Summary';
import 'App.scss';
import PlanetsRotateDesktop from 'components/sections/PlanetsRotateDesktop';
import PlanetsRotateMobile from 'components/sections/PlanetsRotateMobile';
import BackgroundBlob from 'components/elements/BackgroundBlobImage';
import { useState, useEffect, useRef } from 'react';
import CookiePopup from 'components/CookiePopup';


function App() {

  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const [footerReachedHeader, setFooterReachedHeader] = useState<boolean>(false)

  // Watch page height
  useEffect(() => {

    const checkIfIsMobile = () => {
      setIsMobile(window.innerWidth < 640)
    }

    document.addEventListener('resize', checkIfIsMobile)
    checkIfIsMobile()

    return () => document.removeEventListener('resize', checkIfIsMobile)
  }, [])

  return (
    <Context>
      <div id="App" className="bg-black">
        <BackgroundBlob />

        
        <Header footerReachedHeader={footerReachedHeader} />

        {/* SECTIONS */}
        {/* <HeroBlotterLiquid /> */}
        <Hero />
        <Summary />
        {isMobile ? <PlanetsRotateMobile /> : <PlanetsRotateDesktop />}
        <ClippedText />

        {/* GLOBAL ELEMS */}
        <ProgressBar />

        <CookiePopup />
        <Footer setFooterReachedHeader={setFooterReachedHeader} />
      </div>
    </Context>
  );
}

export default App;