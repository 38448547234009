import { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, Euler, Vector3 } from '@react-three/fiber';
import { PerspectiveCamera, Html, Stats } from '@react-three/drei';
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import PlanetCircle from 'components/elements/PlanetCircle';
import { threeVW, threeVH } from "helpers/threeHelpers"
import { animated, easings, useSpring } from 'react-spring';
import PlanetText from 'components/elements/PlanetText';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const PI = Math.PI;


////////////////////////////////////////////////////////////////////////////////////////// PLANETS' TEXT

// Positions    
interface textPositionProps {
    alignOn: "left" | "right";
    xPercent: number;
    yPercent: number
}
/* interface PlanetTextProps {
    title?: string;
    text?: string;
    active: boolean;
    textPosition: textPositionProps;
}
const PlanetText = ({ title, text, active, textPosition }: PlanetTextProps) => {

    const { opacity } = useSpring({
        from: { opacity: active ? 0 : 1 },
        to: { opacity: active ? 1 : 0 },
        config: {
            duration: 400,
            easing: easings.easeOutCubic,
        },
    })

    return (
        <animated.div className={`absolute`} style={{ [textPosition.alignOn]: textPosition.xPercent + '%', bottom: textPosition.yPercent + "%", opacity }}>
            <p className='text-white text-42 font-medium pointer-events-none select-none'>{title}</p>
            <p className='text-white text-18 pointer-events-none select-none w-[clamp(100px,50vw,450px)]'>{text}</p>
        </animated.div>
    )
} */

////////////////////////////////////////////////////////////////////////////////////////// SCENE
interface GlobalPosition {
    position: number[];
    rotation: number[];
    textPosition?: textPositionProps;
}
interface GlobalPositions {
    [key: string]: GlobalPosition
}
interface SceneProps {
    sectionEl: React.RefObject<HTMLElement>;
    cameraEl: any;
    blurRef: any;
    FOV: number; // Field of View
    DOF: number; // Depth of Field
    FD: number; // Focus distance OR the distance on the T axis where your field makes 100 vw
}
const Scene = ({ sectionEl, cameraEl, blurRef, FOV, DOF, FD }: SceneProps) => {

    const [windowWidth, setWindowWidth] = useState(0)
    const [pageHeight, setPageHeight] = useState(0)

    // Refs
    const group1 = useRef<THREE.Group | null>(null)
    const group1_tl = useRef<GSAPTimeline | null>(null)

    type PlanetRef = THREE.Group | null
    const planetRefs = useRef<PlanetRef[]>([])
    /* const p1 = useRef<THREE.Group | null>(null)
    const p2 = useRef<THREE.Group | null>(null)
    const p3 = useRef<THREE.Group | null>(null)
    const p4 = useRef<THREE.Group | null>(null) */

    type PlanetData = {
        title: string;
        activetitle: string;
        activetext: string;
        activeTextPosition: "left" | "right";
        initialPosition: Vector3;
        activeStart: number; // animProgress when the text should appear
        activeEnd: number; // animProgress when the text should disappear
    }
    const planetsData: PlanetData[] = useMemo(() => [
        {
            title: "Creating",
            activetitle: "Creating",
            activetext: "We make your concept clear, challenge your strategy and make sure your vision is understood. From a concept manifesto to the service design and user journeys, we are here to provide you tools, advice and solid visualization.",
            activeTextPosition: "left",
            initialPosition: [threeVW(10), threeVH(-20), threeVW(50)],
            activeStart: 0.18,
            activeEnd: 0.38
        },
        {
            title: "Branding",
            activetitle: "Branding",
            activetext: "We name, write, design and put in motion your brand, token and chain. We create explainers, publish your white paper, make your project shine where it has to be visible.",
            activeTextPosition: "right",
            initialPosition: [threeVW(-50), threeVH(5), threeVW(-25)],
            activeStart: 0.42,
            activeEnd: 0.58
        },
        {
            title: "Funding & regulatory",
            activetitle: "Funding & regulatory",
            activetext: "You only have one chance to make a first great impression. And we are here with you to make sure it’s going to happen. We can help you meet the right people and make sure nothing is left to random regarding your investors and regulatory communication.",
            activeTextPosition: "left",
            initialPosition: [threeVW(-30), threeVH(90), threeVW(-50)],
            activeStart: 0.62,
            activeEnd: 0.78
        },
        {
            title: "Go to market",
            activetitle: "Go to market",
            activetext: "Let’s hook up your clients and partners, develop a full digital and non-digital go to market strategy. Create, design, distribute and monitor your ads, make them better and more efficient. Grow your audience and community.",
            activeTextPosition: "right",
            initialPosition: [threeVW(65), threeVH(75), threeVW(25)],
            activeStart: 0.82,
            activeEnd: 1.1
        },
    ], [windowWidth])

    useEffect(() => {
        planetRefs.current = planetRefs.current.slice(0, planetsData.length);
    }, [planetsData]);


    const globalPositions: GlobalPositions = useMemo(() => ({
        _0: {
            position: [0, 0, threeVW(-100)],
            rotation: [0, 0, 0]
        },
        _1: {
            position: [0, threeVH(-20), threeVW(-50)],
            rotation: [PI / -16, 0, 0],
            textPosition: {
                alignOn: "right",
                xPercent: 55,
                yPercent: 20
            }
        },
        _2: {
            position: [0, threeVH(-20), threeVW(-50)],
            rotation: [PI / 70, PI / 2, 0],
            textPosition: {
                alignOn: "right",
                xPercent: 55,
                yPercent: 30
            }
        },
        _3: {
            position: [0, threeVH(-80), threeVW(-50)],
            rotation: [PI / 70, PI / 1, 0],
            textPosition: {
                alignOn: "left",
                xPercent: 60,
                yPercent: 50
            }
        },
        _4: {
            position: [0, threeVH(-60), threeVW(-65)],
            rotation: [PI / 70, PI * 1.5, 0],
            textPosition: {
                alignOn: "right",
                xPercent: 155,
                yPercent: 20
            }
        },
    }), [FD, windowWidth])

    useEffect(() => {
        // Watch window width
        const refreshWindowWidth = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', refreshWindowWidth)
        refreshWindowWidth()

        // Watch window height
        const resizeHeightObserver = new ResizeObserver(entries => {
            setPageHeight(entries[0].contentRect.height)
        })

        resizeHeightObserver.observe(document.body)
        setPageHeight(document.body.clientHeight)

        return () => {
            resizeHeightObserver?.disconnect()
            window.removeEventListener('resize', refreshWindowWidth)
        }
    }, [])

    /***************** SCROLL HANDLING */
    const [animProgress, setAnimProgress] = useState(0)
    useEffect(() => {
        if (group1.current && !group1_tl.current && planetRefs.current && planetRefs.current[0] && planetRefs.current[1] && planetRefs.current[2] && planetRefs.current[3]) {
            group1_tl.current = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionEl.current,
                    start: `top top`,
                    end: `+=150%`,
                    scrub: 1,
                    markers: false,
                    id: "group1",
                    onUpdate: ({ progress }) => setAnimProgress(progress)
                }
            })
                // position
                .fromTo(group1.current.position, { x: globalPositions._0.position[0], y: globalPositions._0.position[1], z: globalPositions._0.position[2] }, { x: globalPositions._1.position[0], y: globalPositions._1.position[1], z: globalPositions._1.position[2], ease: "none", duration: 1 }, 0)
                .to(group1.current.position, { x: globalPositions._2.position[0], y: globalPositions._2.position[1], z: globalPositions._2.position[2], ease: "none", duration: 1 }, 1)
                .to(group1.current.position, { x: globalPositions._3.position[0], y: globalPositions._3.position[1], z: globalPositions._3.position[2], ease: "none", duration: 1 }, 2)
                .to(group1.current.position, { x: globalPositions._4.position[0], y: globalPositions._4.position[1], z: globalPositions._4.position[2], ease: "none", duration: 1 }, 3)

                // rotation
                .fromTo(group1.current.rotation, { x: globalPositions._0.rotation[0], y: globalPositions._0.rotation[1], z: globalPositions._0.rotation[2] }, { x: globalPositions._1.rotation[0], y: globalPositions._1.rotation[1], z: globalPositions._1.rotation[2], ease: "none", duration: 1 }, 0)
                .to(group1.current.rotation, { x: globalPositions._2.rotation[0], y: globalPositions._2.rotation[1], z: globalPositions._2.rotation[2], ease: "none", duration: 1 }, 1)
                .to(group1.current.rotation, { x: globalPositions._3.rotation[0], y: globalPositions._3.rotation[1], z: globalPositions._3.rotation[2], ease: "none", duration: 1 }, 2)
                .to(group1.current.rotation, { x: globalPositions._4.rotation[0], y: globalPositions._4.rotation[1], z: globalPositions._4.rotation[2], ease: "none", duration: 1 }, 3)

                // circles rotation
                .fromTo([planetRefs.current[0].rotation, planetRefs.current[1].rotation, planetRefs.current[2].rotation, planetRefs.current[3].rotation], { x: -1 * globalPositions._0.rotation[0], y: -1 * globalPositions._0.rotation[1], z: -1 * globalPositions._0.rotation[2] }, { x: -1 * globalPositions._1.rotation[0], y: -1 * globalPositions._1.rotation[1], z: -1 * globalPositions._1.rotation[2], ease: "none", duration: 1 }, 0)
                .to([planetRefs.current[0].rotation, planetRefs.current[1].rotation, planetRefs.current[2].rotation, planetRefs.current[3].rotation], { x: -1 * globalPositions._2.rotation[0], y: -1 * globalPositions._2.rotation[1], z: -1 * globalPositions._2.rotation[2], ease: "none", duration: 1 }, 1)
                .to([planetRefs.current[0].rotation, planetRefs.current[1].rotation, planetRefs.current[2].rotation, planetRefs.current[3].rotation], { x: -1 * globalPositions._3.rotation[0], y: -1 * globalPositions._3.rotation[1], z: -1 * globalPositions._3.rotation[2], ease: "none", duration: 1 }, 2)
                .to([planetRefs.current[0].rotation, planetRefs.current[1].rotation, planetRefs.current[2].rotation, planetRefs.current[3].rotation], { x: -1 * globalPositions._4.rotation[0], y: -1 * globalPositions._4.rotation[1], z: -1 * globalPositions._4.rotation[2], ease: "none", duration: 1 }, 3)
        }
        return () => {
            if (group1_tl.current) {
                group1_tl.current.kill()
                group1_tl.current = null
            }
        }
    }, [globalPositions, sectionEl, pageHeight]) // paheHeight is here to force the gsap timeline to be refreshed when the page is resized
    /************************* */

    ///////////// GO TO A SPECIFIC PLANET
    const goToPlanet = (step_num: number): void => {
        if (!sectionEl.current) return
        console.log("scroooll")

        const sectionDistanceToTop = window.pageYOffset + sectionEl.current.getBoundingClientRect().top
        const sectionHeight = window.innerHeight * 1.5 // 150vh
        const nb_step = Object.keys(globalPositions).length - 1
        const heightOfOneStep = sectionHeight / nb_step
        const distanceToScroll = heightOfOneStep * step_num + sectionDistanceToTop

        gsap.to(window, {
            scrollTo: { y: distanceToScroll, autoKill: false },
            ease: Power3.easeInOut,
            duration: 1
        });
    }

    // Test blur effect
    /* const [reverse, setReverse] = useState(false) */
    /* useFrame(() => {
        if (!reverse && blurRef.current.blurPass.scale < 0.01) setReverse(true)
        if (reverse && blurRef.current.blurPass.scale > 0.99) setReverse(false)
        
        blurRef.current.blurPass.scale += reverse ? 0.01 : -0.01
    }) */

    return (
        <>

            <ambientLight />

            <group>
                <group ref={group1} position={globalPositions._0.position as Vector3} rotation={globalPositions._0.rotation as Euler}>

                    {
                        planetsData.map((planet, i) => 
                            <PlanetCircle key={i} ref={el => planetRefs.current[i] = el} initialPosition={planet.initialPosition} onClick={() => goToPlanet(i + 1)} title={planet.title} active={animProgress > planet.activeStart && animProgress < planet.activeEnd} activetitle={planet.activetitle} activetext={planet.activetext} activeTextPosition={planet.activeTextPosition} />
                        )
                    }

                </group>
            </group>


            {/* <Html wrapperClass='pointer-events-none' fullscreen>
                <PlanetText active={animProgress > 0.22 && animProgress < 0.38} textPosition={globalPositions._1.textPosition as textPositionProps} title="Investor’s release" text="Praesent vel maximus quam, ac mollis libero.  Fusce sollicitudin aliquam nisl non tempor. Nulla tincidunt dolor vel finibus cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
                <PlanetText active={animProgress > 0.42 && animProgress < 0.58} textPosition={globalPositions._2.textPosition as textPositionProps} title="Regulatory" text="Praesent vel maximus quam, ac mollis libero.  Fusce sollicitudin aliquam nisl non tempor. Nulla tincidunt dolor vel finibus cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
                <PlanetText active={animProgress > 0.62 && animProgress < 0.78} textPosition={globalPositions._3.textPosition as textPositionProps} title="Communication" text="Praesent vel maximus quam, ac mollis libero.  Fusce sollicitudin aliquam nisl non tempor. Nulla tincidunt dolor vel finibus cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
                <PlanetText active={animProgress > 0.82 && animProgress < 1.1} textPosition={globalPositions._4.textPosition as textPositionProps} title="Pitch decks" text="Praesent vel maximus quam, ac mollis libero.  Fusce sollicitudin aliquam nisl non tempor. Nulla tincidunt dolor vel finibus cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
            </Html> */}


        </>
    )
}


////////////////////////////////////////////////////////////////////////////////////////// SECTION
const PlanetsRotateDesktop = () => {

    const cameraEl = useRef<any>(null)
    const sectionEl = useRef<HTMLElement>(null)
    const blurRef = useRef<any>(null)


    return (
        <section ref={sectionEl} className="section-planets-zoom relative h-[300vh]">
            <div className="sticky top-0 w-full h-[150vh] overflow-hidden">

                <Canvas
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        height: '150vh',
                        transform: 'translateY(-25vh)'
                    }}
                >
                    <pointLight position={[200, 0, 100]} />

                    {/* <EffectComposer> */}
                    {/* <CustomDepthOfField camera={cameraEl} /> */}
                    {/* <Bloom luminanceThreshold={0.1}  luminanceSmoothing={0.1} height={300} intensity={10} /> */}
                    {/* <Noise opacity={0.02} /> */}
                    {/* <Vignette eskil={false} offset={0.1} darkness={1.1} /> */}
                    {/* <SelectiveBloom  /> */}
                    {/* <CustomBlur ref={blurRef} /> */}

                    {/* <kawaseBlurPass /> */}
                    {/* </EffectComposer> */}

                    {/* <Effects multisamping={8} renderIndex={1} disableGamma={false} disableRenderPass={false} disableRender={false}> */}
                    {/* <gaussianBlurPass ref={blurRef} kernelSize={1} width={10} height={10} iterations={0.1} /> */}
                    {/* @ts-ignore */}
                    {/* <kawaseBlurPass ref={blurRef} opacity={0} />
                    </Effects> */}

                    <PerspectiveCamera ref={cameraEl} makeDefault fov={70} position={[0, 0, 200]} />

                    <Scene sectionEl={sectionEl} cameraEl={cameraEl} blurRef={blurRef} FOV={70} DOF={-1} FD={200} />

                    {process.env.REACT_APP_ENV !== 'prod' &&
                        <Stats />
                    }
                </Canvas>

                {/* Mobile fixed texts */}


            </div>
        </section>
    )
}
export default PlanetsRotateDesktop