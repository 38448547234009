import "./SVG_google_partner.scss"

interface Props {
	className?: string
}
const SVG_google_partner = ({ className }: Props) => {


	return (
		<svg version="1.1" id="g" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 140 133.7" style={{["enableBackground" as any]: "new 0 0 140 133.7"}} xmlSpace="preserve">
			<g id="g_1_">
				<path id="g_4_" className="st0" d="M136.5,133.3H3.5c-1.7,0-3.1-1.4-3.1-3.1V3.5c0-1.7,1.4-3.1,3.1-3.1h133c1.7,0,3.1,1.4,3.1,3.1
		v126.7C139.6,131.9,138.2,133.3,136.5,133.3L136.5,133.3z"/>
				<path id="g_3_" className="st1" d="M136.5,133.7H3.5c-1.9,0-3.5-1.6-3.5-3.5V3.5C0,1.6,1.6,0,3.5,0h133c1.9,0,3.5,1.6,3.5,3.5v126.7
		C140,132.1,138.4,133.7,136.5,133.7z M3.5,0.8C2,0.8,0.8,2,0.8,3.5v126.7c0,1.5,1.2,2.7,2.7,2.7h133c1.5,0,2.7-1.2,2.7-2.7V3.5
		c0-1.5-1.2-2.7-2.7-2.7H3.5z M58.8,86.5h1.4v10.2h-1.4V86.5z M48.9,91.1c0.3,0.6,0.5,1.3,0.5,2c0,0.7-0.2,1.4-0.5,2
		c-0.3,0.6-0.8,1.1-1.4,1.4c-0.6,0.3-1.2,0.5-1.9,0.5c-0.7,0-1.4-0.2-1.9-0.5c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2
		c0-0.7,0.2-1.4,0.5-2c0.3-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.2-0.5,1.9-0.5s1.4,0.2,1.9,0.5C48.1,90,48.6,90.5,48.9,91.1z M47.6,94.4
		c0.2-0.4,0.3-0.9,0.3-1.4c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.7-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3
		c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9c0.7,0.4,1.6,0.4,2.3,0
		C47.1,95.1,47.4,94.8,47.6,94.4z M32.5,91.2h-5v1.3h3.7c0,0.4-0.1,0.7-0.3,1.1c-0.1,0.4-0.3,0.7-0.6,0.9c-0.3,0.3-0.7,0.6-1.1,0.8
		c-0.4,0.2-1,0.3-1.6,0.3c-0.7,0-1.3-0.2-1.9-0.5c-0.6-0.3-1.1-0.8-1.4-1.4c-0.4-0.6-0.5-1.3-0.5-2.1c0-0.8,0.2-1.5,0.5-2.1
		c0.4-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.2-0.5,1.9-0.5c0.5,0,1.1,0.1,1.5,0.3c0.5,0.2,0.9,0.5,1.2,0.9l0.1,0.1l1-1l-0.1-0.1
		c-0.4-0.5-1-0.9-1.6-1.1c-0.7-0.3-1.4-0.4-2.1-0.4c-1,0-1.9,0.2-2.7,0.7c-0.8,0.5-1.5,1.1-1.9,1.9c-0.5,0.8-0.7,1.7-0.7,2.7
		c0,1,0.2,1.9,0.7,2.7c0.5,0.8,1.1,1.5,1.9,1.9s1.7,0.7,2.7,0.7c1.6,0,2.8-0.5,3.8-1.5c0.4-0.4,0.8-1,1-1.6c0.2-0.6,0.3-1.2,0.3-1.8
		c0-0.2,0-0.5-0.1-0.7L32.5,91.2z M40.6,91.1c0.3,0.6,0.5,1.3,0.5,2c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.8,1.1-1.4,1.4
		c-0.6,0.3-1.2,0.5-1.9,0.5c-0.7,0-1.4-0.2-1.9-0.5c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.3-0.5-2c0-0.7,0.2-1.4,0.5-2
		c0.3-0.6,0.8-1.1,1.4-1.4c0.6-0.3,1.2-0.5,1.9-0.5s1.4,0.2,1.9,0.5C39.8,90,40.2,90.5,40.6,91.1z M39.3,94.4
		c0.2-0.4,0.3-0.9,0.3-1.4c0-0.5-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.7-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3
		c-0.4,0.2-0.7,0.5-0.9,0.9C35,92,34.9,92.5,34.9,93c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9c0.7,0.4,1.6,0.4,2.3,0
		C38.8,95.1,39.1,94.8,39.3,94.4z M55.9,89.4h1.4v7c0,1.2-0.3,2.1-1,2.7c-0.7,0.6-1.5,0.9-2.6,0.9c-0.9,0-1.6-0.2-2.1-0.6
		c-0.6-0.4-0.9-0.9-1.1-1.5l0-0.1l1.3-0.6l0,0.1c0.1,0.4,0.4,0.7,0.7,0.9c0.3,0.2,0.8,0.4,1.2,0.4c0.7,0,1.2-0.2,1.6-0.6
		c0.4-0.4,0.6-0.9,0.6-1.7v-0.5c-0.2,0.3-0.5,0.5-0.9,0.7c-0.4,0.2-0.9,0.4-1.5,0.4c-0.6,0-1.2-0.2-1.7-0.5
		c-0.5-0.3-0.9-0.8-1.3-1.4c-0.3-0.6-0.5-1.3-0.5-2c0-0.7,0.2-1.4,0.5-2c0.3-0.6,0.7-1.1,1.3-1.4c0.5-0.3,1.1-0.5,1.7-0.5
		c0.6,0,1.1,0.1,1.5,0.4c0.3,0.2,0.6,0.4,0.9,0.7V89.4z M54.8,90.7c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0-0.8,0.1-1.1,0.3
		c-0.3,0.2-0.6,0.5-0.8,0.9c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.8,0.9c0.3,0.2,0.7,0.3,1.1,0.3
		c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.5,0.8-0.9c0.2-0.4,0.3-0.9,0.3-1.4c0-0.5-0.1-1-0.3-1.4C55.4,91.2,55.2,90.9,54.8,90.7z
		 M112.2,91c0.3,0.6,0.4,1.2,0.4,1.9c0,0.1,0,0.3,0,0.4l0,0.1H107c0,0.5,0.2,0.9,0.4,1.2c0.2,0.4,0.5,0.6,0.8,0.8
		c0.3,0.2,0.7,0.3,1.1,0.3c0.9,0,1.5-0.4,2-1.2l0.1-0.1l1.2,0.6l-0.1,0.1c-0.3,0.6-0.8,1.1-1.3,1.4c-0.5,0.3-1.2,0.5-2,0.5
		c-0.7,0-1.3-0.2-1.9-0.5c-0.6-0.3-1-0.8-1.3-1.4c-0.3-0.6-0.5-1.3-0.5-2c0-0.7,0.1-1.4,0.4-2c0.3-0.6,0.7-1.1,1.3-1.4
		c0.5-0.4,1.2-0.5,1.9-0.5c0.7,0,1.3,0.2,1.9,0.5C111.5,89.9,111.9,90.4,112.2,91z M107.8,91c-0.3,0.3-0.6,0.7-0.7,1.2h4.1
		c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.3-1.1-0.3C108.6,90.4,108.1,90.6,107.8,91z M101.7,89.1
		c-0.5,0-1,0.1-1.4,0.4c-0.3,0.2-0.6,0.4-0.8,0.7v-0.8h-1.4v7.4h1.4v-4.1c0-0.4,0.1-0.7,0.2-1c0.2-0.3,0.4-0.6,0.7-0.8
		c0.3-0.2,0.6-0.3,1-0.3c0.5,0,0.9,0.1,1.2,0.4c0.3,0.3,0.4,0.7,0.4,1.4v4.5h1.4V92c0-0.9-0.2-1.6-0.7-2.1
		C103.3,89.4,102.6,89.1,101.7,89.1z M96.1,95.6c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.2-0.2-0.4-0.2-0.8v-3.8H97v-1.3h-1.7v-2h-1.4v2h-1.2
		v1.3h1.2v4.1c0,0.4,0,0.7,0.1,0.9c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.1,0.5,0.1,0.8,0.1c0.2,0,0.4,0,0.6,0
		c0.2,0,0.3-0.1,0.5-0.1l0.1,0v-1.5l-0.2,0.1C96.6,95.5,96.4,95.6,96.1,95.6z M118,89.3c-0.2-0.1-0.5-0.2-0.9-0.2
		c-0.5,0-0.9,0.1-1.3,0.4c-0.3,0.2-0.5,0.4-0.7,0.7v-0.9h-1.4v7.4h1.4v-4.1c0-0.4,0.1-0.7,0.2-1c0.2-0.3,0.4-0.6,0.7-0.7
		c0.3-0.2,0.6-0.3,0.9-0.3c0.4,0,0.7,0.1,1,0.2l0.2,0.1L118,89.3L118,89.3z M91.2,89.3c-0.5,0-1,0.1-1.4,0.4
		c-0.3,0.2-0.5,0.4-0.7,0.7v-0.9h-1.4v7.4h1.4v-4.1c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.8-0.6,1.4-0.6c0.3,0,0.5,0,0.6,0.1l0.1,0v-1.5
		l-0.1,0C91.6,89.3,91.5,89.3,91.2,89.3z M68.1,91c0.3,0.6,0.4,1.2,0.4,1.9c0,0.2,0,0.3,0,0.4l0,0.1h-5.6c0,0.5,0.2,0.9,0.4,1.2
		c0.2,0.4,0.5,0.6,0.8,0.8c0.3,0.2,0.7,0.3,1.1,0.3c0.9,0,1.5-0.4,2-1.2l0.1-0.1l1.2,0.6L68.3,95c-0.3,0.6-0.8,1.1-1.3,1.4
		c-0.5,0.3-1.2,0.5-2,0.5c-0.7,0-1.3-0.2-1.9-0.5c-0.6-0.3-1-0.8-1.3-1.4c-0.3-0.6-0.5-1.3-0.5-2c0-0.7,0.1-1.4,0.4-2
		c0.3-0.6,0.7-1.1,1.3-1.4c0.5-0.4,1.2-0.5,1.9-0.5c0.7,0,1.3,0.2,1.9,0.5C67.4,89.9,67.8,90.4,68.1,91z M63.6,91
		c-0.3,0.3-0.6,0.7-0.7,1.2H67c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.3-1.1-0.3C64.4,90.4,64,90.6,63.6,91
		z M78.8,88c0.3,0.5,0.4,1,0.4,1.6s-0.1,1.1-0.4,1.6c-0.3,0.5-0.7,0.8-1.2,1.1c-0.5,0.3-1,0.4-1.6,0.4H74v4.1h-1.4V86.5H76
		c0.6,0,1.1,0.1,1.6,0.4C78.1,87.1,78.5,87.5,78.8,88z M77.6,90.4c0.1-0.3,0.2-0.5,0.2-0.8s-0.1-0.6-0.2-0.8
		c-0.1-0.3-0.4-0.5-0.6-0.6c-0.3-0.2-0.6-0.2-0.9-0.2H74v3.4h2.1c0.3,0,0.7-0.1,0.9-0.2C77.3,90.9,77.5,90.6,77.6,90.4z M85.7,89.9
		c0.6,0.5,0.9,1.3,0.9,2.2v4.6h-1.4v-0.8c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.3-0.9,0.4-1.4,0.4c-0.5,0-1-0.1-1.5-0.3
		c-0.4-0.2-0.8-0.5-1-0.9c-0.2-0.4-0.4-0.8-0.4-1.3c0-0.8,0.3-1.4,0.9-1.9c0.6-0.4,1.3-0.7,2.2-0.7c0.4,0,0.8,0,1.2,0.1
		c0.3,0.1,0.5,0.1,0.7,0.2v-0.2c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.3-0.5c-0.4,0-0.7,0.1-1,0.2c-0.3,0.2-0.6,0.4-0.7,0.7
		l-0.1,0.1l-1.1-0.8l0.1-0.1c0.3-0.4,0.7-0.8,1.2-1c0.5-0.2,1.1-0.4,1.7-0.4C84.3,89.1,85.1,89.4,85.7,89.9z M84.1,95.4
		c0.3-0.2,0.6-0.5,0.8-0.8c0.2-0.3,0.3-0.6,0.3-1c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.7-0.1-1-0.1c-0.6,0-1.1,0.1-1.4,0.4
		c-0.3,0.2-0.5,0.6-0.5,1c0,0.4,0.1,0.6,0.4,0.9c0.3,0.2,0.6,0.3,1.1,0.3C83.4,95.7,83.8,95.6,84.1,95.4z"/>
				<g id="g_2_">
					<path className="st2" d="M90.5,54c0-1.5-0.2-2.9-0.5-4.3H70.4v8.2h11c-0.5,2.6-1.7,4.9-4,6.4v5.3h6.8C88.2,65.9,90.5,60.5,90.5,54
			L90.5,54z"/>
					<path className="st3" d="M70.3,74.7c5.7,0,10.5-1.9,13.9-5.1l-6.8-5.3c-1.9,1.3-4.3,2-7.1,2c-5.5,0-10.1-3.7-11.8-8.7h-7V63
			C55,69.9,62.1,74.7,70.3,74.7L70.3,74.7z"/>
					<path className="st4" d="M58.5,57.6c-0.4-1.3-0.7-2.6-0.7-4c0-1.4,0.2-2.7,0.7-4v-5.5h-7c-1.4,2.8-2.2,6.1-2.2,9.5
			c0,3.4,0.8,6.6,2.2,9.5L58.5,57.6L58.5,57.6z"/>
					<path className="st5" d="M70.3,40.8c3.1,0,5.9,1.1,8,3.2l6-6c-3.6-3.4-8.4-5.5-14.1-5.5c-8.2,0-15.3,4.7-18.8,11.6l7,5.5
			C60.2,44.5,64.8,40.8,70.3,40.8L70.3,40.8z"/>
				</g>
			</g>
		</svg>
	)
}
export default SVG_google_partner
