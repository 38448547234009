import { useCallback, useState, RefCallback } from "react";

const useRerenderingRef = <T,>(): [RefCallback<T>, T | null] => {    
    const [refCurrent, setRefCurrent] = useState<T | null>(null);
    const ref: RefCallback<T> = useCallback((node: T | null) => {
        if (node !== null) {
            setRefCurrent(node);
        }
    }, []);
    return [ref, refCurrent];
}
export default useRerenderingRef