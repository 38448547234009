import { useState, createContext, useEffect } from "react"

interface WindowContextProps {
    /* windowHeight: number;
    windowWidth: number; */
    scrollProgress: number;
}

export const WindowContext = createContext<WindowContextProps>({
    /* windowHeight: 0,
    windowWidth: 0, */
    scrollProgress: 0
})

interface Props {
    children: React.ReactNode;
}
export default function Context({ children }: Props) {
    /* const [windowHeight, setWindowHeight] = useState(0)
    const [windowWidth, setWindowWidth] = useState(10) */
    const [pageHeight, setPageHeight] = useState(0)
    const [scrollProgress, setScrollProgress] = useState<number>(0)

    /* Keeps window total height up to date */
    /* useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowHeight(window.innerHeight)
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', updateWindowDimensions)
        updateWindowDimensions()

        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []) */

    /* Keeps window total page height up to date */
    useEffect(() => {
        const refreshTotalHeight = () => {
            setPageHeight(document.documentElement.scrollHeight - document.documentElement.clientHeight)
        }

        window.addEventListener('resize', refreshTotalHeight)
        refreshTotalHeight()

        return () => {
            window.removeEventListener('resize', refreshTotalHeight)
        }
    }, [])

    /* Keeps scroll progress up to date */
    useEffect(() => {
        const refreshScrollProgress = () => {
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            setScrollProgress((scrollTop / pageHeight) * 100)
        }

        window.addEventListener('scroll', refreshScrollProgress)

        return () => {
            window.removeEventListener('scroll', refreshScrollProgress)
        }
    }, [pageHeight])

    return (
        <WindowContext.Provider value={{ /* windowHeight, windowWidth,  */scrollProgress }}>
            {children}
        </WindowContext.Provider>
    )
}