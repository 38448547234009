// Lets you specify a Three position in percentage of the screen width
const threeVW = ( percentage: number ): number => {
    const CALIBRATION = 20 / 3 // ratio between the THREE width and the screen width in px
    return (window.innerWidth / CALIBRATION) * (percentage / 100)
}

// Lets you specify a Three position in percentage of the screen height
const threeVH = ( percentage: number ): number => {
    const CALIBRATION = (1230 / 186) * 1.5 // ratio between the THREE width and the screen width in px
    return (window.innerHeight / CALIBRATION) * (percentage / 100)
}

export { threeVW, threeVH }