import { useEffect, useRef, useState } from "react"
import ReactGA from "react-ga4";
//@ts-ignore
import Cookies from 'js-cookie';

const CookiePopup = () => {
    const [popupVisible, setPopupVisible] = useState(false)
    const [popupDisplayed, setPopupDisplayed] = useState(false)

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

    const closePopup = () => {
        setPopupVisible(false)
        setTimeout(() => {
            setPopupDisplayed(false)
        }, 500)
    }

    const disableGA = () => {
        Cookies.remove('_ga')
        Cookies.remove('_gid')
        Cookies.remove('_gat')
    }
    const initGA = () => {
        const GA_ID = process.env.REACT_APP_GA_MEASUREMENT_ID // NEXT_PUBLIC_GA_MEASUREMENT_ID
        if (GA_ID && GA_ID !== "") {
            ReactGA.initialize(GA_ID);
        }
    }
    const decline = () => {
        Cookies.set('cookie-consent', 'declined', { expires: 30 })
        disableGA()
        closePopup()
    }
    const accept = () => {
        Cookies.set('cookie-consent', 'accepted', { expires: 180 })
        initGA()
        closePopup()
    }

    useEffect(() => {
        const cookieConsent = Cookies.get('cookie-consent')

        if (cookieConsent === 'accepted') {
            initGA()
        }
        else if (cookieConsent === 'declined') {
            disableGA()
        }
        else if (!cookieConsent || cookieConsent === 'unset') {
            timeoutRef.current = setTimeout(() => {
                setPopupDisplayed(true)
                setTimeout(() => {
                    setPopupVisible(true)
                }, 100)
            }, 2500)
        }

        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, [])

    return (
        <div className={`fixed left-gutter-mobile right-gutter-mobile bottom-gutter-mobile sm:left-auto sm:right-gutter-mobile sm:ml-gutter-mobile bg-white px-[18px] py-[18px] rounded-[10px] text-14 transition-all duration-medium ${popupDisplayed ? '' : 'hidden'} ${popupVisible ? 'translate-y-0 opacity-1' : 'translate-y-[100px] opacity-0'}`}>
            <div className="flex items-start sm:items-center flex-col sm:flex-row">
                <p className="mb-[12px] sm:mb-0 mr-[22px]">We use Google Analytics to analyze our traffic.</p>
                <div className="flex max-sm:w-full justify-end">
                    <button type="button" className="max-sm:grow border border-black hover:text-purple-rgba py-[8px] px-[16px] rounded-full" onClick={decline}>Decline</button>
                    <button type="button" onClick={accept} className="max-sm:grow ml-[8px] border border-purple-rgba text-purple-rgba hover:border-black py-[8px] px-[16px] rounded-full">Accept</button>
                </div>
            </div>
        </div>
    )
}
export default CookiePopup