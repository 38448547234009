import { forwardRef, Fragment, useEffect, useRef, useState } from "react"
import SVG_Instagram from "components/svg/social/SVG_Instagram"
import SVG_LinkedIn from "components/svg/social/SVG_LinkedIn"
import SVG_google_partner from "components/svg/SVG_google_partner"
import SVG_Facebook from "components/svg/social/SVG_Facebook"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

interface FooterProps {
    setFooterReachedHeader: React.Dispatch<React.SetStateAction<boolean>>
}
const Footer = ({setFooterReachedHeader}: FooterProps) => {
    const currentYear = new Date().getFullYear()
    const footerRef = useRef<HTMLElement>(null)

    const addresses = [
        {
            title: "Horde Lausanne",
            link: "https://goo.gl/maps/qpDxNA6wF4ch3hWY7",
            lines: [
                "Av. Benjamin-Constant 1",
                "1003 Lausanne",
                "Suisse",
            ]
        },
        {
            title: "Horde Zürich",
            link: "https://goo.gl/maps/X93xAf3isuqZ689v5",
            lines: [
                "Werdstrasse 109",
                "8004 Zürich",
                "Suisse",
            ]
        },
        {
            title: "Horde Genève",
            link: "https://goo.gl/maps/fHwsg4jFFFvn1u416",
            lines: [
                "Rue de St-Jean 56",
                "1203 Genève",
                "Suisse",
            ]
        },
        {
            title: "Horde Bern",
            link: "https://goo.gl/maps/MRTQydqRUwGyNYUU6",
            lines: [
                "Kornhausplatz 10",
                "3011 Bern",
                "Suisse",
            ]
        },
        {
            title: "Horde Basel",
            lines: [
                "Riehenring 65",
                "4058 Basel",
                "Suisse"
            ]
        }
    ]

    // If social icons are overed (boolean)
    const [socialOvered, setSocialOvered] = useState(false)
    // Which social icon is overed
    const [overedSocial, setOveredSocial] = useState(0)

    useEffect(() => {
        if(!footerRef.current) return

        const st = new ScrollTrigger({
            trigger: footerRef.current,
            start: "top top+=50px",
            onEnter: () => setFooterReachedHeader(true),
            onLeaveBack: () => setFooterReachedHeader(false),
        })

        return () => st.kill()
    }, [])

    return (
        <footer ref={footerRef} className="bg-white sticky top-full inverted-colors">
            <div className="sizing-medium flex flex-col pt-[100px] sm:pt-[150px]">

                <div className="flex flex-col lg:flex-row justify-between lg:mb-[100px]">
                    {/* CONTACT INFOS */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-[120px] gap-y-[50px] mb-[50px]">
                        {addresses.map((address, index) => (
                            <div key={`block${index}`} className="flex flex-col">
                                <span className="text-24 sm:text-30 font-bold">{address.title}</span>
                                <p>
                                    {address.link ?
                                        (
                                            <a href={address.link} className="hover:underline" target="_blank" rel="noreferrer">
                                                {address.lines.map((line, i) => (
                                                    <Fragment key={`line${i}`}><span>{line}</span><br /></Fragment>
                                                ))}
                                            </a>
                                        )
                                        :
                                            address.lines.map((line, i) => (
                                                <Fragment key={`line${i}`}><span>{line}</span><br /></Fragment>
                                            )
                                        )
                                    }
                                </p>
                            </div>
                        ))}
                    </div>

                    {/* GOOGLE PARTNERS */}
                    <div className="w-[150px] mb-[50px]">
                        <SVG_google_partner />
                    </div>

                </div>

                {/* BOTTOM LINE */}
                <div className="flex flex-col sm:flex-row justify-between sm:items-end lg:pb-[60px] mt-auto font-light">

                    {/* PHONE & MAIL */}
                    <div className="flex flex-col text-24 sm:text-30 font-bold mb-[40px] mr-[40px]">
                        <a href="tel:0041215445000">+41 21 544 50 00</a>
                        <a href="mailto:info@horde.ch">info@horde.ch</a>
                    </div>

                    {/* SOCIAL */}
                    <div className="relative flex items-center mb-[32px]" onMouseEnter={() => { setSocialOvered(true) }} onMouseLeave={() => { setSocialOvered(false) }}>
                        {/* hover effect */}
                        <div className={`absolute left-0 -z-[1] w-[64px] h-[64px] bg-gradient-radial-purple rounded-full transition-[transform,opacity] duration-fast ease-out ${socialOvered ? 'opacity-40' : 'opacity-0'}`} style={{ transform: `translateX(${Math.max(overedSocial - 1, 0) * 64 - 20}px` }}></div>
                        {/* icons */}
                        <a className="pr-[20px]" href="https://www.instagram.com/horde/" target="_blank" rel="noreferrer" aria-label="Instagram" onMouseEnter={() => setOveredSocial(1)}>
                            <SVG_Instagram className="w-[24px]" />
                        </a>
                        <a className="p-[20px]" href="https://www.facebook.com/wearehorde/" target="_blank" rel="noreferrer" aria-label="Facebook" onMouseEnter={() => setOveredSocial(2)}>
                            <SVG_Facebook className="w-[24px]" />
                        </a>
                        <a className="pl-[23px]" href="https://www.linkedin.com/company/horde" target="_blank" rel="noreferrer" aria-label="LinkedIn" onMouseEnter={() => setOveredSocial(3)}>
                            <SVG_LinkedIn className="w-[22px]" />
                        </a>
                    </div>
                </div>

            </div>
        </footer>
    )
}
export default Footer