import { useEffect, useState, useRef, useContext, useMemo } from "react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Ellipse_4 from 'assets/images/Ellipse_4_500.png'
import Ellipse_3 from 'assets/images/Ellipse_3.png'
import Ellipse_6 from 'assets/images/Ellipse_6_800.png'

gsap.registerPlugin(ScrollTrigger);

interface BlobData {
    width: number,
    left?: number,
    right?: number,
    top: number,
    rotate: number,
    image: string,
}
interface BlobGroupProps {
    className?: string,
    blobsData: BlobData[],
}
const BlobGroup = ({ className, blobsData }: BlobGroupProps) => {

    
    const Blobs = blobsData.map((blobData, i) => {
        const { width, left, right, top, rotate, image } = blobData
        const direction = left ? { left: left + '%' } : { right: right + '%' }
        return (
            <div key={width + ' ' + left + ' ' + right + ' ' + top + ' ' + rotate + ' ' + i} className={`absolute`} style={{ ...direction, top: top + 'vh', width: `max(${width}vw, ${width/1.3}vh)`, transform: `rotate(${rotate}deg)` }}>
                <img src={image} alt="" className="w-full" />
            </div>
        )
    })

    return (
        <>
            <div className={`blob-group absolute top-0 left-0 w-full h-full ${className}`}>
                {Blobs}
            </div>
        </>
    )
}

const BackgroundBlob = () => {

    const sectionRef = useRef<HTMLElement>(null)

    const blobsData = [
        {
            width: 70,
            left: 60,
            top: 8,
            rotate: 200,
            image: Ellipse_4
        },
        {
            width: 70,
            right: 70,
            top: 100,
            rotate: 120,
            image: Ellipse_6
        },
        {
            width: 80,
            left: 63,
            top: 160,
            rotate: 300,
            image: Ellipse_3
        },
    ]

    useEffect(() => {

        // parallax effect
        const tlParallax = gsap.timeline({
            scrollTrigger: {
                trigger: "#root",
                start: `top top`,
                end: "bottom bottom",
                scrub: 1,
            }
        }).fromTo(sectionRef.current, { y: 0 }, { y: -window.innerHeight * 2, duration: 1, ease: "linear" })


        return () => {
            tlParallax.scrollTrigger?.kill()
            tlParallax.kill()
        }

    }, [])

    return (
        <section ref={sectionRef} className="h-[300vh] bg-black fixed top-0 left-0 w-screen overflow-x-clip bg-[linear-gradient(297.18deg,#000000_40.6%,_#373737_100%)]" >

            <BlobGroup blobsData={blobsData} />

        </section>
    )
}

export default BackgroundBlob