import { useSpring, easings, animated } from "react-spring";

interface PlanetTextProps {
    title?: string;
    text?: string;
    active: boolean;
    textPosition: "left" | "right" | null;
}
const PlanetText = ({ title, text, active, textPosition }: PlanetTextProps) => {

    const { opacity } = useSpring({
        from: { opacity: active ? 0 : 1 },
        to: { opacity: active ? 1 : 0 },
        config: {
            duration: 400,
            easing: easings.easeOutCubic,
        },
    })

    const textPositionClass = textPosition ? (textPosition == 'left' ? {'right': '13vw'} : {'left': '18vw'}) : null

    return (
        <animated.div className={`fixed max-sm:bottom-[42px] max-sm:left-gutter-mobile sm:absolute bg-[rgba(0,0,0,0.4)] shadow-[rgba(0,0,0,0.4)_0px_0px_80px_75px] rounded-full`} style={{ ...textPositionClass, opacity }}>
            <p className={`text-white text-30 sm:text-42 leading-tiny mb-[12px] ${active ? 'pointer-events-auto' : 'pointer-events-none'} font-medium`}>{title}</p>
            <p className={`text-white text-18 leading-small ${active ? 'pointer-events-auto' : 'pointer-events-none'} w-[calc(100vw_-_2_*_theme(spacing.gutter-mobile))] sm:w-[clamp(100px,50vw,450px)]`}>{text}</p>
        </animated.div>
    )
}
export default PlanetText