interface Props{
    className?: string
}
const SVG_Arrow_down_fat = ({ className }: Props) => {


    return (
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="6 4 20 24">
            <polygon points="24.59 16.59 17 24.17 17 4 15 4 15 24.17 7.41 16.59 6 18 16 28 26 18 24.59 16.59"></polygon>
        </svg>
    )
}
export default SVG_Arrow_down_fat

