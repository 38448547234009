import { stringToKey } from "helpers/stringToKey";
import { useEffect, useMemo, useRef, useState } from "react";
import "styles/BorderGradient.scss"
import "./SummaryBox.scss"

/****************************** BOX COMPONENT */
interface BoxProps {
    title: string;
    texts: string[];
    openedBox: string;
    setOpenedBox: React.Dispatch<React.SetStateAction<string>>
}
const Box = ({ title, texts, openedBox, setOpenedBox }: BoxProps) => {

    const collapsibleRef = useRef<HTMLDivElement>(null)
    const opened = useMemo(() => {
        return openedBox === title
    }, [openedBox])

    useEffect(() => {
        if(!collapsibleRef.current) return
        if (opened) {
            collapsibleRef.current.style.maxHeight = collapsibleRef.current.scrollHeight + "px"
        } else {
            collapsibleRef.current.style.maxHeight = "0px"
        }
    }, [opened])

    const toggleOpened = () => {
        setOpenedBox(opened ? "" : title)
    }

    return (
        <article className={`summary-box border-gradient before:rounded-[10px] after:rounded-[10px] w-full mb-[20px] last:mb-0 rounded-[10px] ${opened ? "opened" : ""}`}>
            <button className="flex justify-between w-full p-[16px] pb-[12px]" onClick={toggleOpened}>
                <h2 className="text-24 lg:text-30 font-medium text-left">{title}</h2>
                <div className="plus-sign shrink-0 ml-[22px]">
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div ref={collapsibleRef} className="overflow-hidden transition-[max-height] duration-medium px-[16px]">
                {texts.map(text => <p key={stringToKey(text)} className="py-[12px] border-t border-white last:pb-[22px]">{text}</p>)}
            </div>
        </article>
    )
}
/*********************************/

const Summary = () => {

    const [openedBox, setOpenedBox] = useState("")

    const data = [
        {
            title: "Strategy and Interaction design",
            texts: [
                "Formalising Your Ideas and Concepts",
                "Third parties and Advising Ressources",
                "Building Your Team",
                "Visualisation of Your Concept",
                "Interaction Design",
                "Service Design",
                "Motion Design Explainer",
                "Pitch Deck and Presentation",
            ]
        },
        {
            title: "Naming, Branding, Identity and Product",
            texts: [
                "Brand Manifesto",
                "Company Branding",
                "Product Branding",
                "Token Naming & Identity",
                "Metaverse Digital Asset",
                "Blockchain Communication Advising",
                "NFT Communication Advising",
            ]
        },
        {
            title: "Investors & regulatory Communication",
            texts: [
                "Striking Out Your Concept & Highlighting Your Ideas",
                "Visual and Written Communication",
                "Short Explainer",
                "Long Explainer",
                "ICO Communication",
                "Investor’s Release",
                "Investors documents",
                "Regulatory Documents",
            ]
        },
        {
            title: "Community, Social impact & sustainability",
            texts: [
                "Buyers communication",
                "Community building",
                "Discord Creation & Management",
                "Digital Advertising",
                "Physical assets",
            ]
        },
    ]


    return (
        <section className="relative section-summary text-white">
            <div className="sizing-medium py-[120px] sm:py-[calc(100px+theme(spacing.header-height-desktop))] min-h-screen">

                {/* TITLE */}
                <div className="grid grid-rows-1 grid-cols-4 gap-[24px]">
                    <div className="text-wrapper text-clamp-42 leading-small font-bold mb-[50px] sm:mb-[100px] col-span-4 sm:col-span-3 [@media(min-width:1800px)]:col-span-2">
                        <p>Because we’ve been down that road too, we are here to help you at every stage of your project.</p>
                    </div>
                </div>

                {/* BOXES */}
                <div className="flex flex-col">
                    {data.map((item) => <Box key={stringToKey(item.title)} title={item.title} texts={item.texts} openedBox={openedBox} setOpenedBox={setOpenedBox} />)}
                </div>

            </div>
        </section>
    )
}
export default Summary