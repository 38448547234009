import { useState, useEffect } from "react"
import SVG_Web3HordeLogo from "./svg/SVG_Web3HordeLogo"

interface HeaderProps {
    footerReachedHeader: boolean
}
const Header = ({footerReachedHeader}: HeaderProps) => {

    const [logoWhiteOpacity, setLogoWhiteOpacity] = useState(100)
    const [logoBlackOpacity, setLogoBlackOpacity] = useState(0)

    useEffect(() => {
        if(footerReachedHeader) {
            setLogoWhiteOpacity(0)
            setLogoBlackOpacity(100)
        } else {
            setLogoWhiteOpacity(100)
            setLogoBlackOpacity(0)
        }
    }, [footerReachedHeader])

    return (
        <header className="bg-[rgba(0,0,0,0)] backdrop-blur-[22px] fixed top-0 w-full h-header-height-desktop z-[10]">
            <div className="sizing-medium h-full flex items-center">
                <div className="logo-wrapper h-full grid grid-cols-1 grid-rows-1 items-center">
                    <SVG_Web3HordeLogo className={`col-start-1 col-span-1 row-start-1 row-span-1 bottom-0 left-0 w-[186px] transition-opacity duration-[0.3s] opacity-${logoWhiteOpacity}`} color="white" />
                    <SVG_Web3HordeLogo className={`col-start-1 col-span-1 row-start-1 row-span-1 bottom-0 left-0 w-[186px] transition-opacity duration-[0.3s] opacity-${logoBlackOpacity}`} color="black" />
                </div>
            </div>
        </header>
    )
}
export default Header