import SVG_Arrow_down_fat from "components/svg/SVG_Arrow_down_fat"
import scrollToBottomOfElem from "helpers/scrollToBottomOfElem"
import { useRef } from "react"
import "styles/BorderGradient.scss"

const Hero = () => {

    const sectionRef = useRef<HTMLElement>(null)

    return (
        <section ref={sectionRef} className="relative section-hero text-white">
            <div className="sizing-medium min-h-screen">

                <div className="flex flex-col pt-[120px] pb-[100px] sm:py-0 justify-between sm:justify-center min-h-screen max-w-clamp-120">
                    <h1 className="text-[6.5vh] sm:text-clamp-120 leading-none font-title mb-[10vh] uppercase">We design concepts and content for your web3 projects</h1>

                    <button className="border-gradient button-scroll p-[10px] w-full sm:w-fit before:rounded-full after:rounded-full" onClick={() => scrollToBottomOfElem({ target: sectionRef.current })}>
                        <span className="flex items-center justify-between sm:justify-start px-[16px]">
                            <span className="py-[8px]">Keep scrolling</span>
                            <span>
                                <SVG_Arrow_down_fat className="ml-[16px] w-[20px] fill-white" />
                            </span>
                        </span>
                    </button>
                </div>

            </div>
        </section>
    )
}
export default Hero