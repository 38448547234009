import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

interface Props {
    target: HTMLElement | null;
}
const scrollToBottomOfElem = ({ target }: Props) => {

    if (!target) return

    const targetHeight = target.clientHeight
    const targetOffsetTop = target.offsetTop

    gsap.to(window, { duration: 1, scrollTo: targetHeight + targetOffsetTop, ease: "power2" });

}

export default scrollToBottomOfElem