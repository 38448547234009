import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, Euler, Vector3 } from '@react-three/fiber';
import { PerspectiveCamera, Html, Stats } from '@react-three/drei';
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import PlanetCircle from 'components/elements/PlanetCircle';
import { threeVW, threeVH } from "helpers/threeHelpers"
import { animated, easings, useSpring } from 'react-spring';
import PlanetText from 'components/elements/PlanetText';
import useRerenderingRef from 'components/hooks/useRerenderingRef';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const PI = Math.PI;
const SIZE = 2; // Used to increase the planets orbit size easily


////////////////////////////////////////////////////////////////////////////////////////// PLANETS' TEXT

// Positions    
interface textPositionProps {
    alignOn: "left" | "right";
    xPercent: number;
    yPercent: number
}

////////////////////////////////////////////////////////////////////////////////////////// SCENE
interface GlobalPosition {
    position: number[];
    rotation: number[];
    textPosition?: textPositionProps;
}
interface GlobalPositions {
    [key: string]: GlobalPosition
}
interface SceneProps {
    sectionEl: React.RefObject<HTMLElement>;
    cameraEl: any;
    blurRef: any;
    FOV: number; // Field of View
    DOF: number; // Depth of Field
    FD: number; // Focus distance OR the distance on the T axis where your field makes 100 vw
    canvasWrapperRefCurrent: HTMLDivElement | null;
}
const Scene = ({ sectionEl, cameraEl, blurRef, FOV, DOF, FD, canvasWrapperRefCurrent }: SceneProps) => {

    const [windowWidth, setWindowWidth] = useState(0)
    const [pageHeight, setPageHeight] = useState(0)

    // Refs
    const group1 = useRef<THREE.Group | null>(null)
    const group1_tl = useRef<GSAPTimeline | null>(null)

    type PlanetRef = THREE.Group | null
    const planetRefs = useRef<PlanetRef[]>([])

    type PlanetData = {
        title: string;
        activetitle: string;
        activetext: string;
        activeTextPosition: "left" | "right";
        initialPosition: Vector3;
        activeStart: number; // animProgress when the text should appear
        activeEnd: number; // animProgress when the text should disappear
    }
    const planetsData: PlanetData[] = useMemo(() => [
        {
            title: "Creating",
            activetitle: "Creating",
            activetext: "We make your concept clear, challenge your strategy and make sure your vision is understood. From a concept manifesto to the service design and user journeys, we are here to provide you tools, advice and solid visualization.",
            activeTextPosition: "left",
            initialPosition: [threeVW(-70 * SIZE), 0, threeVW(70 * SIZE)],
            activeStart: 0.02,
            activeEnd: 0.17
        },
        {
            title: "Branding",
            activetitle: "Branding",
            activetext: "We name, write, design and put in motion your brand, token and chain. We create explainers, publish your white paper, make your project shine where it has to be visible.",
            activeTextPosition: "right",
            initialPosition: [threeVW(-100 * SIZE), 0, 0],
            activeStart: 0.25,
            activeEnd: 0.40
        },
        {
            title: "Funding &\n regulatory",
            activetitle: "Funding & regulatory",
            activetext: "You only have one chance to make a first great impression. And we are here with you to make sure it’s going to happen. We can help you meet the right people and make sure nothing is left to random regarding your investors and regulatory communication.",
            activeTextPosition: "left",
            initialPosition: [threeVW(-70 * SIZE), 0, threeVW(-70 * SIZE)],
            activeStart: 0.48,
            activeEnd: 0.63
        },
        {
            title: "Go to\n market",
            activetitle: "Go to market",
            activetext: "Let’s hook up your clients and partners, develop a full digital and non-digital go to market strategy. Create, design, distribute and monitor your ads, make them better and more efficient. Grow your audience and community.",
            activeTextPosition: "right",
            initialPosition: [0, 0, threeVW(-100 * SIZE)],
            activeStart: 0.71,
            activeEnd: 0.9
        },
    ], [windowWidth])

    useEffect(() => {
        planetRefs.current = planetRefs.current.slice(0, planetsData.length);
    }, [planetsData]);


    const globalPositions: GlobalPositions = useMemo(() => ({
        _0: {
            position: [threeVW(50 * SIZE), 0, threeVW(-100 * SIZE)],
            rotation: [PI / 4, 0, 0]
        },
        _1: {
            position: [threeVW(50 * SIZE), threeVH(-100), threeVW(-100 * SIZE)],
            rotation: [0, PI, 0],
        },
    }), [FD, windowWidth])

    useEffect(() => {
        // Watch window width
        const refreshWindowWidth = () => setWindowWidth(window.innerWidth)

        window.addEventListener('resize', refreshWindowWidth)
        refreshWindowWidth()

        // Watch window height
        const resizeHeightObserver = new ResizeObserver(entries => {
            setPageHeight(entries[0].contentRect.height)
        })

        resizeHeightObserver.observe(document.body)
        setPageHeight(document.body.clientHeight)

        return () => {
            resizeHeightObserver?.disconnect()
            window.removeEventListener('resize', refreshWindowWidth)
        }
    }, [])

    /***************** SCROLL HANDLING */
    const [animProgress, setAnimProgress] = useState(0)
    useEffect(() => {
        if (group1.current && !group1_tl.current && planetRefs.current && planetRefs.current[0] && planetRefs.current[1] && planetRefs.current[2] && planetRefs.current[3]) {
            group1_tl.current = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionEl.current,
                    start: `top top-=50%`,
                    end: `+=150%`,
                    scrub: 1,
                    markers: false,
                    id: "group1",
                    onUpdate: ({ progress }) => {
                        setAnimProgress(progress)
                    },
                }
            })
                // rotation
                .fromTo(group1.current.rotation, { x: globalPositions._0.rotation[0], y: globalPositions._0.rotation[1], z: globalPositions._0.rotation[2] }, { x: globalPositions._1.rotation[0], y: globalPositions._1.rotation[1], z: globalPositions._1.rotation[2], ease: "none", duration: 1 }, 0)
                // position
                .fromTo(group1.current.position, { x: globalPositions._0.position[0], y: globalPositions._0.position[1], z: globalPositions._0.position[2] }, { x: globalPositions._1.position[0], y: globalPositions._1.position[1], z: globalPositions._1.position[2], ease: "none", duration: 1 }, 0)
                
                // circles rotation
                .fromTo([planetRefs.current[0].rotation, planetRefs.current[1].rotation, planetRefs.current[2].rotation, planetRefs.current[3].rotation], { x: -1 * globalPositions._0.rotation[0], y: -1 * globalPositions._0.rotation[1], z: -1 * globalPositions._0.rotation[2] }, { x: -1 * globalPositions._1.rotation[0], y: -1 * globalPositions._1.rotation[1], z: -1 * globalPositions._1.rotation[2], ease: "none", duration: 1 }, 0)
        }
        return () => {
            if (group1_tl.current) {
                group1_tl.current.kill()
                group1_tl.current = null
            }
        }
    }, [globalPositions, sectionEl, pageHeight])
    /************************* */

    ///////////// GO TO A SPECIFIC PLANET
    const goToPlanet = useCallback((step_num: number): void => {
        if (!sectionEl.current || !canvasWrapperRefCurrent) return

        const manualOffset = -45
        const sectionDistanceToTop = window.pageYOffset + sectionEl.current.getBoundingClientRect().top + window.innerWidth / 2
        const sectionHeight = canvasWrapperRefCurrent?.clientHeight / 25 * 15
        const nb_step = 4
        const heightOfOneStep = sectionHeight / nb_step
        const distanceToScroll = heightOfOneStep * step_num + sectionDistanceToTop + manualOffset

        gsap.to(window, {
            scrollTo: { y: distanceToScroll, autoKill: false },
            ease: Power3.easeInOut,
            duration: 1
        });
    }, [canvasWrapperRefCurrent])


    return (
        <>

            <ambientLight />

            <group position={[0, threeVH(120), 0]}>
                <group ref={group1} position={globalPositions._0.position as Vector3} rotation={globalPositions._0.rotation as Euler}>

                    {
                        planetsData.map((planet, i) => 
                            <PlanetCircle key={i} radius={20} ref={el => planetRefs.current[i] = el} initialPosition={planet.initialPosition} onClick={() => goToPlanet(i + 1)} color='white' title={planet.title} active={animProgress > planet.activeStart && animProgress < planet.activeEnd} activetitle={planet.activetitle} activetext={planet.activetext} activeTextPosition={planet.activeTextPosition} />
                        )
                    }

                </group>
            </group>

            {/* active text mobile */}
            <Html wrapperClass="bottom-[75vh] top-[unset]" calculatePosition={() => [0,0,0] } >
                {
                    planetsData.map((planet, i) => {
                        return <PlanetText key={"planetTextMobile"+i} active={animProgress > planet.activeStart && animProgress < planet.activeEnd} title={planet.title} text={planet.activetext} textPosition={null}  />
                    })
                }
            </Html>


        </>
    )
}


////////////////////////////////////////////////////////////////////////////////////////// SECTION
const PlanetsRotateMobile = () => {

    const cameraEl = useRef<any>(null)
    const sectionEl = useRef<HTMLElement>(null)
    const [canvasWrapperRef, canvasWrapperRefCurrent] = useRerenderingRef<HTMLDivElement>()
    const blurRef = useRef<any>(null)


    return (
        <section ref={sectionEl} className="section-planets-zoom relative h-[400vh]">
            <div ref={canvasWrapperRef} className="sticky top-[-50vh] w-full h-[250vh] overflow-hidden">

                <Canvas
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        height: '250vh',
                        transform: 'translateY(-25vh)'
                    }}
                >
                    <pointLight position={[200, 0, 100]} />

                    {/* <EffectComposer> */}
                    {/* <CustomDepthOfField camera={cameraEl} /> */}
                    {/* <Bloom luminanceThreshold={0.1}  luminanceSmoothing={0.1} height={300} intensity={10} /> */}
                    {/* <Noise opacity={0.02} /> */}
                    {/* <Vignette eskil={false} offset={0.1} darkness={1.1} /> */}
                    {/* <SelectiveBloom  /> */}
                    {/* <CustomBlur ref={blurRef} /> */}

                    {/* <kawaseBlurPass /> */}
                    {/* </EffectComposer> */}

                    {/* <Effects multisamping={8} renderIndex={1} disableGamma={false} disableRenderPass={false} disableRender={false}> */}
                    {/* <gaussianBlurPass ref={blurRef} kernelSize={1} width={10} height={10} iterations={0.1} /> */}
                    {/* @ts-ignore */}
                    {/* <kawaseBlurPass ref={blurRef} opacity={0} />
                    </Effects> */}

                    <PerspectiveCamera ref={cameraEl} makeDefault fov={70} position={[0, 0, 200]} />

                    <Scene sectionEl={sectionEl} canvasWrapperRefCurrent={canvasWrapperRefCurrent} cameraEl={cameraEl} blurRef={blurRef} FOV={100} DOF={-1} FD={200} />

                    {process.env.REACT_APP_ENV !== 'prod' &&
                        <Stats />
                    }
                </Canvas>

                {/* Mobile fixed texts */}


            </div>
        </section>
    )
}
export default PlanetsRotateMobile