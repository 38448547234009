import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSpring, animated, easings } from 'react-spring'
import { useLayoutEffect, useRef, useState } from "react";
import "./ClippedText.scss"

gsap.registerPlugin(ScrollTrigger);

const ClippedText = () => {

    interface ClippedLineProps {
        text: string;
    }
    const ClippedLine = ({text}: ClippedLineProps) => {

        const lineRef = useRef<HTMLDivElement>(null)
        const [boundingTop, setBoundingTop] = useState<number>(100)
        const springedStyle = useSpring({ 
            to: { backgroundPositionX: boundingTop+"%" },
            config: {
                duration: 400,
                easing: easings.easeOutCubic,
            },
        })

        useLayoutEffect(() => {
            if(lineRef.current) {
                ScrollTrigger.create({
                    trigger: lineRef.current,
                    start: "bottom bottom-=10%",
                    end: "top top+=40%",
                    onUpdate: self => setBoundingTop((1 - self.progress) * 100)
                });
            }
        }, [])

        return (
            <animated.div ref={lineRef} style={springedStyle}>{text}</animated.div>
        )
    }

    return (
        <section className="relative section-clipped-text max-sm:mt-[-75vh] pb-[100px] sm:pb-[150px]">
            <div className="sizing-medium text-white text-[11.6vw] sm:text-clamp-120 font-medium leading-tiny">
                <div className="clipped-texts-wrapper max-sm:hidden">
                    <ClippedLine text="Let’s hook up" />
                    <ClippedLine text="your clients" />
                    <ClippedLine text="and partners," />
                    <ClippedLine text="develop" />
                    <ClippedLine text="a full digital" />
                    <ClippedLine text="and non-digital" />
                    <ClippedLine text="go to market" />
                    <ClippedLine text="strategy." />
                    <ClippedLine text="Create, design," />
                    <ClippedLine text="distribute and" />
                    <ClippedLine text="monitor your ads," />
                    <ClippedLine text="make them better" />
                    <ClippedLine text="and more" />
                    <ClippedLine text="efficient." />
                    <ClippedLine text="Grow" />
                    <ClippedLine text="your audience" />
                    <ClippedLine text="and community." />
                </div>
                <div className="clipped-texts-wrapper sm:hidden">
                    <ClippedLine text="Let’s" />
                    <ClippedLine text="hook up" />
                    <ClippedLine text="your clients" />
                    <ClippedLine text="and partners," />
                    <ClippedLine text="develop" />
                    <ClippedLine text="a full digital" />
                    <ClippedLine text="and" />
                    <ClippedLine text="non-digital" />
                    <ClippedLine text="go to" />
                    <ClippedLine text="market" />
                    <ClippedLine text="strategy." />
                    <ClippedLine text="Create," />
                    <ClippedLine text="design," />
                    <ClippedLine text="distribute" />
                    <ClippedLine text="and monitor" />
                    <ClippedLine text="your ads," />
                    <ClippedLine text="make them" />
                    <ClippedLine text="better" />
                    <ClippedLine text="and more" />
                    <ClippedLine text="efficient." />
                    <ClippedLine text="Grow" />
                    <ClippedLine text="your audience" />
                    <ClippedLine text="and" />
                    <ClippedLine text="community." />
                </div>
            </div>
        </section>
    )
}
export default ClippedText