interface Props {
    className?: string
}
const SVG_LinkedIn = ({ className }: Props) => {


    return (
        <svg className={`${className} `} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.98193 0.75C0.887684 0.75 0 1.63622 0 2.73047C0 3.82472 0.886969 4.73145 1.98047 4.73145C3.07397 4.73145 3.9624 3.82472 3.9624 2.73047C3.9624 1.63697 3.07618 0.75 1.98193 0.75ZM11.6514 6C9.98712 6 9.03487 6.87034 8.57812 7.73584H8.52979V6.23291H5.25V17.25H8.66748V11.7964C8.66748 10.3594 8.77595 8.9707 10.5542 8.9707C12.3069 8.9707 12.3325 10.6092 12.3325 11.8872V17.25H15.7456H15.75V11.1987C15.75 8.23773 15.1134 6 11.6514 6ZM0.272461 6.23291V17.25H3.69287V6.23291H0.272461Z" fill="black" />
        </svg>
    )
}
export default SVG_LinkedIn