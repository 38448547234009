import { useContext } from "react"
import { useSpring, animated, easings } from 'react-spring'
import { WindowContext } from "contexts/WindowContext"

const ProgressBar = () => {

    const { scrollProgress } = useContext(WindowContext)
    const springedStyle = useSpring({ 
        to: {transform: `translateX(${(scrollProgress / 1.7 - 66)+'%'})` },
        config: {
            duration: 400,
            easing: easings.easeOutCubic,
        },
    })

    return (
        <div className="progress-bar fixed bottom-0 left-0 w-full z-[2]">
            <div className="progress-wrapper bg-purple-rgba h-[8px]">
                <animated.div className="progress-fg w-[300%] bg-gradient-progress-bar h-full" style={springedStyle} ></animated.div>
            </div>
        </div>
    )
}
export default ProgressBar