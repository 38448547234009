import WindowContextProvider from 'contexts/WindowContext'

interface Props {
    children: React.ReactNode;
}
export default function Context({ children }: Props) {
    return (
        <WindowContextProvider>
            {children}
        </WindowContextProvider>
    )
}